import React from 'react';
import './Home.css'; // Assuming you have a CSS file for styling

export const Home = () => {
  return (
    <div className="home-container">
      <main className="home-main">
        <h1>Talha Khan</h1>
        <div className="social-media-links">
          <a href="https://x.com/TalhaKhan_TK_" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://www.linkedin.com/in/Talha--Khan " target="_blank" rel="noopener noreferrer">LinkedIn</a>
        </div>
        <p className="contact-email">Email: Talha0128@gmail.com</p>
        <section className="books">
          <h3>Books I've Read</h3>
          <ul>
            <li>Torah</li>
            <li>Gospel</li>
            <li>Prodigal Genius the Story of Nikola Tesla</li>
            <li>Zero to One</li>
            <li>The Maverick and the Machine Thomas Watson Sr</li>
            <li>The greatest capitalist to ever live Thomas Watson Jr</li>

            <li>The secret history of the mongols Igor’s version, the short one</li>
            <li>Steve Jobs</li>
            <li>Alexander Hamilton - Ron Chernow</li>
            <li>The Secrets of Sand Hill Road</li>
            <li>Innovators Dillema</li>
            <li>Genghis Khan and the making of the Modern World</li>
            <li>Steve Jobs</li>
          </ul>
          <h3>Books I'm Reading</h3>
          <li>The Quran - M.A.S Abdel Haleem Translation</li>
          <li>Traction - Gabriel Weinberg</li>
          <li>The History of Western Philosophy Bertrand Russel</li>
          <li>In The Plex</li>

          <h3>I believe you can learn from all religions in this life</h3>

        </section>
      </main>
    </div>
  );
};
